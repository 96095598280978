import React, { useState, useEffect } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Button, Toggle } from '../../Common'
import {
  useTeachers,
  updateTeacher,
  deleteTeacher,
  setTeacherPassword,
} from '../../api'

const EditTeacher = () => {
  const { data: teachers, loading } = useTeachers()
  const { teacherId } = useParams<{ teacherId: string }>()
  const teacher = teachers?.find((t) => t.email === teacherId)
  const [isAdmin, setIsAdmin] = useState(teacher?.isAdmin || false)
  const history = useHistory()
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  useEffect(() => {
    setIsAdmin(!!teacher?.isAdmin)
  }, [teacher])

  return (
    <>
      <div>
        <Link to="/teachers" className="text-blue-700 hover:text-blue-600">
          Back to Teachers
        </Link>
      </div>

      <div className="text-2xl font-bold pb-4">Edit Teacher</div>
      <Row label="First Name">{teacher?.firstName}</Row>
      <Row label="Last Name">{teacher?.lastName}</Row>
      <Row label="Email">{teacher?.email}</Row>
      <Row label="Teacher is a SAMTA Administrator">
        {!loading && (
          <Toggle value={isAdmin} onClick={() => setIsAdmin(!isAdmin)} />
        )}
      </Row>
      <div className="text-right">
        <Button color="white" onClick={() => history.push('/teachers')}>
          Cancel
        </Button>
        <Button
          color="white"
          onClick={async () => {
            const password = window.prompt('New Password:')
            if (password === undefined) return
            else if (!password || password?.length < 6) {
              window.alert(
                'Please choose a password that is at least 6 characters long.'
              )
              return
            }
            await setTeacherPassword({
              email: teacher?.email as string,
              password,
            })
          }}
        >
          Reset Password
        </Button>
        {confirmingDelete ? (
          <Button
            color="red"
            onClick={async () => {
              if (!teacher) return
              await deleteTeacher(teacher.email)
              history.push('/teachers')
            }}
          >
            Confirm Delete
          </Button>
        ) : (
          <Button color="white" onClick={() => setConfirmingDelete(true)}>
            Delete Teacher
          </Button>
        )}
        <Button
          color="pink"
          onClick={async () => {
            if (!teacher?.email) return
            await updateTeacher({ email: teacher.email, isAdmin })
            history.push('/teachers')
          }}
        >
          Update Teacher
        </Button>
      </div>
    </>
  )
}

const Row = ({ label, children }: { label: string; children: any }) => (
  <div className="flex flex-col sm:flex-row my-2">
    <div className="sm:w-1/3 font-bold">{label}</div>
    <div className="sm:w-2/3">{children}</div>
  </div>
)

export default EditTeacher

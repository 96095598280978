import React from 'react'
import { addEvent } from '../../api'
import EventEditor from './Editor'

const AddEvent = () => (
  <EventEditor
    title="Add Event"
    onSubmit={addEvent}
    submitText="Finish Adding Event"
  />
)

export default AddEvent

import React from 'react'
import { useTeachers } from '../../api'
import { Loading } from '../../Common'
import { useHistory } from 'react-router'

export default () => {
  const { data, loading } = useTeachers()
  const teachers = data || []
  const history = useHistory()

  return (
    <div>
      <div className="text-2xl font-bold">Teachers</div>
      <table className="table-auto mt-8 mx-auto">
        <thead>
          <tr>
            <td className="font-bold text-center px-4 py-2">Name</td>
            <td className="font-bold text-center px-4 py-2">Email</td>
            <td className="font-bold text-center px-4 py-2">
              Site Administrator
            </td>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td colSpan={3}>
                <Loading />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {teachers.map((t, i) => (
              <tr
                key={i}
                className="hover:bg-gray-100 cursor-pointer"
                onClick={() => history.push(`/teachers/${t.email}`)}
              >
                <td className="border px-4 py-2">
                  <div className="px-2 py-1 border border-1 border-transparent">
                    {t.firstName} {t.lastName}
                  </div>
                </td>
                <td className="border px-4 py-2">
                  <div className="px-2 py-1 border border-1 border-transparent">
                    {t.email}
                  </div>
                </td>
                <td className="border px-4 py-2">
                  <div className="px-2 py-1 border border-1 border-transparent">
                    {t.isAdmin ? 'Yes' : 'No'}
                  </div>
                </td>
              </tr>
            ))}
            <tr>
              <td
                className="border px-4 py-2 cursor-pointer text-center font-bold hover:bg-gray-100 text-pink-600 hover:text-pink-700"
                colSpan={4}
                onClick={() => history.push('/teachers/add')}
              >
                + Add Teacher
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  )
}

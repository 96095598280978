import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const Button = ({
  className,
  children,
  to,
  color = 'blue',
  ...props
}: {
  className?: string
  to?: string
  children: any
  color?: string
  [k: string]: any
}) =>
  to ? (
    <Link to={to}>
      <Button className={className} color={color} {...props}>
        {children}
      </Button>
    </Link>
  ) : (
    <button
      {...props}
      className={`text-center border ${
        color === 'white' ? 'border-gray-500' : 'border-white'
      } bg-${color}-500 rounded ${
        color === 'white' ? 'text-gray-600' : 'text-white'
      } hover:bg-gray-200 py-2 px-4 hover:bg-${color}-700 select-none ${
        className || ''
      }`}
    >
      {children}
    </button>
  )

export const Input = ({
  className,
  ...props
}: {
  className?: string
  [key: string]: any
}) => (
  <input
    {...props}
    className={`px-2 py-1 rounded-sm border bg-blue-100 ${className || ''}`}
  />
)

export const Loading = (): JSX.Element => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 500)
    return () => clearTimeout(timeout)
  }, [])
  return show ? <>Loading...</> : <></>
}

export const Toggle = ({
  value,
  onClick,
}: {
  value: boolean
  onClick: any
}) => (
  <div
    className={`cursor-pointer rounded-sm  text-center w-12 shadow font-bold px-2 py-1 select-none ${
      value ? 'bg-green-200' : 'bg-gray-200'
    } ${value ? 'text-green-700' : 'text-gray-600'}`}
    onClick={onClick}
  >
    {value ? 'Yes' : 'No'}
  </div>
)

export const NowLaterToggle = ({
  value,
  onClick,
  labels,
}: {
  value: boolean
  onClick: any
  labels?: { yes: string; no: string }
}) => (
  <div
    className={`cursor-pointer rounded-sm  text-center w-30 shadow font-bold px-2 py-1 select-none ${
      value ? 'bg-green-200' : 'bg-gray-200'
    } ${value ? 'text-green-700' : 'text-gray-600'}`}
    onClick={onClick}
  >
    {value ? labels?.yes || 'Yes' : labels?.no || 'No'}
  </div>
)

import React, { useReducer, useEffect } from 'react'
import ReactDOM from 'react-dom'
import {
  useRegistrations,
  useStudents,
  useEvent,
  useRegistrationCount,
  registerEvent,
  Column,
  Student,
} from '../../api'
import { useParams, Link } from 'react-router-dom'
import { Button, Toggle, Loading } from '../../Common'
import produce from 'immer'
import { renderDollars } from '../../utils'
import { useHistory } from 'react-router'

declare global {
  interface Window {
    paypal: any
  }
}
const PayPalButton = window.paypal.Buttons.driver('react', {
  React,
  ReactDOM,
})

interface Registartion extends Student {
  attending: boolean
  [key: string]: any
  columns: {
    [key: string]: any
  }
}
const reducer = (
  state: any[],
  action: { type: string; [key: string]: any }
) => {
  switch (action.type) {
    case 'reset':
      return (action.students || []).map((student: any) => ({
        ...student,
        attending: false,
        columns: {},
      }))
    case 'toggle':
      return produce(state, (nextState) => {
        nextState[action.rowIndex].attending = !nextState[action.rowIndex]
          .attending
      })
    case 'setColumn':
      return produce(state, (nextState) => {
        nextState[action.rowIndex].columns[action.columnName] = action.value
      })
    default:
      return state
  }
}
export default () => {
  const { data: students, loading: loadingStudents } = useStudents()
  const { eventId } = useParams<{ eventId: string }>()
  const { data: event, loading: loadingEvent } = useEvent(eventId || '')
  const [registrations, dispatch] = useReducer(reducer, [])
  const { data: existingRegistrations } = useRegistrations(eventId as string)
  const history = useHistory()
  const count = registrations.filter((r: any) => r.attending).length
  const { data: registrationCount } = useRegistrationCount(eventId || '')

  useEffect(() => {
    dispatch({ type: 'reset', students, columns: event?.columns || {} })
  }, [dispatch, students, event])

  if (loadingEvent || loadingStudents) return <Loading />
  if (!event || !eventId) return <span>Event not found</span>

  const register = async ({ orderId }: { orderId: string | null }) => {
    try {
      await registerEvent(
        eventId as string,
        registrations
          .filter((r: any) => r.attending)
          .map(({ attending, ...entry }: any) => entry),
        orderId
      )
      history.push(`/events/${eventId}`)
    } catch (e) {
      console.log({ e, str: e + '' })
    }
  }

  const validationErrors = event?.columns
    ?.filter((c) => c.required)
    .some((c) =>
      registrations.some(
        (r: any) =>
          r.attending &&
          ((r.columns || {})[c.name] === undefined ||
            (r.columns || {})[c.name] === '')
      )
    )
  const spotsAvailable: number | false =
    typeof event.maxEntries === 'number' &&
    typeof registrationCount === 'number' &&
    event.maxEntries - registrationCount
  const maxEntriesError = spotsAvailable !== false && spotsAvailable < count

  return (
    <>
      <div>
        <Link
          to={`/events/${eventId}`}
          className="text-blue-700 hover:text-blue-600"
        >
          Back to Event Page
        </Link>
      </div>
      <div className="text-2xl font-bold">Register for {event.name}</div>
      {students?.length === 0 ? (
        <div className="mt-8">
          Please add your students first then come back to register for this
          event.
        </div>
      ) : (
        <>
          <div>
            Registration fee: {event.fee ? renderDollars(event.fee) : 'none'}
          </div>
          {spotsAvailable !== false && (
            <div>Available Spots: {spotsAvailable}</div>
          )}
          <table className="table-auto mt-8 w-full">
            <thead>
              <tr>
                <td>Name</td>
                <td>Attending</td>
                {(event.columns || []).map((column, i) => (
                  <td key={i}>
                    {column.name}
                    {column.required ? (
                      <span className="text-red-600 font-bold">*</span>
                    ) : null}
                  </td>
                ))}
              </tr>
            </thead>
            <tbody>
              {registrations.map((r: any, i: number) => (
                <tr key={i}>
                  <td className="border px-4 py-2">
                    {r.firstName} {r.lastName}
                  </td>
                  <td className="border px-4 py-2">
                    {(existingRegistrations || []).find(
                      (e) => e.studentId === r.studentId
                    ) ? (
                      <div className="text-green-600 font-bold">Registered</div>
                    ) : (
                      <Toggle
                        value={r.attending}
                        onClick={() =>
                          dispatch({ type: 'toggle', rowIndex: i })
                        }
                      />
                    )}
                  </td>
                  {(event.columns || []).map((column, j) => (
                    <td className="border px-4 py-2" key={j}>
                      {r.attending && (
                        <ColumnInput
                          column={column}
                          value={registrations[i].columns[column.name]}
                          onChange={(value) =>
                            dispatch({
                              type: 'setColumn',
                              rowIndex: i,
                              columnName: column.name,
                              value,
                            })
                          }
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <p className="pt-2 text-right">
            <span className="text-red-600 font-bold">*</span>Required
          </p>
          <p className="pt-8">
            Registering{' '}
            <strong>
              {count} student{count === 1 ? '' : 's'}
            </strong>
            . Total cost:{' '}
            <strong>
              {event?.fee ? renderDollars(event.fee * count) : renderDollars(0)}
            </strong>
          </p>
          <div
            className={
              count === 0 || validationErrors
                ? 'pt-4 pointer-events-none opacity-25 flex flex-row'
                : 'pt-4 flex flex-row'
            }
          >
            {event?.fee ? (
              <>
                <div style={{ width: 200 }}>
                  <PaymentButton
                    eventName={event.name}
                    fee={event.fee}
                    onComplete={({ orderId }) => register({ orderId })}
                    names={registrations
                      .filter((r: Registartion) => r.attending)
                      .map((r: Registartion) => `${r.firstName} ${r.lastName}`)}
                  />
                </div>
                <div className="px-8 pt-8">
                  <strong>or</strong>
                </div>
                <div className="pt-4">
                  <Button
                    color="pink"
                    onClick={() => register({ orderId: null })}
                  >
                    Pay Later
                  </Button>
                </div>
              </>
            ) : (
              <Button color="pink" onClick={() => register({ orderId: null })}>
                Finish Registration
              </Button>
            )}
          </div>

          {validationErrors && (
            <p className="text-red-600">
              Please fill out the required columns for each student you're
              registering.
            </p>
          )}
          {maxEntriesError && (
            <p className="text-red-600">
              {spotsAvailable === 0
                ? 'There are no available spots for this event.'
                : spotsAvailable === 1
                ? `There is only 1 available spot for this event. Please register fewer students.`
                : `There are only ${spotsAvailable} available spots for this event. Please register fewer students.`}
            </p>
          )}
        </>
      )}
    </>
  )
}

const ColumnInput = ({
  column,
  value,
  onChange,
}: {
  column: Column
  value: any
  onChange: (value: any) => void
}) => {
  if (column.type === 'checkbox')
    return (
      <input
        type="checkbox"
        className="px-2 py-1 rounded-sm border bg-blue-100"
        checked={!!value}
        onChange={() => onChange(!value)}
      />
    )
  if (column.type === 'string')
    return (
      <input
        className="px-2 py-1 rounded-sm border bg-blue-100"
        placeholder={column.placeholder || ''}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      />
    )
  if (column.type === 'number')
    return (
      <input
        type="number"
        className="px-2 py-1 rounded-sm border bg-blue-100"
        placeholder={column.placeholder || ''}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      />
    )
  if (column.type === 'textarea')
    return (
      <textarea
        className="px-2 py-1 rounded-sm border bg-blue-100"
        placeholder={column.placeholder || ''}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
      />
    )
  else return null
}

const PaymentButton = ({
  names,
  eventName,
  fee,
  onComplete,
}: {
  names: string[]
  eventName: string
  fee: number
  onComplete: (result: { orderId: string }) => void
}) => {
  const createOrder = (_data: any, actions: any) =>
    actions.order.create({
      purchase_units: [
        {
          amount: {
            value: names.length * fee + '',
            breakdown: {
              item_total: {
                value: names.length * fee + '',
                currency_code: 'USD',
              },
            },
          },
          description: `Registration for ${eventName}`,
          items: names.map((name) => ({
            name: `${name}`,
            quantity: '1',
            unit_amount: {
              value: fee + '',
              currency_code: 'USD',
            },
          })),
        },
      ],
    })

  const onApprove = async (data: any, actions: any) => {
    await actions.order.capture()
    onComplete({ orderId: data.orderID })
  }
  return (
    <div className="w-20">
      <PayPalButton
        createOrder={createOrder}
        onApprove={onApprove}
        style={{
          shape: 'rect',
          color: 'blue',
          layout: 'vertical',
          label: 'pay',
        }}
      />
    </div>
  )
}

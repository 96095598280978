import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Toggle } from '../../Common'
import { addTeacher } from '../../api'

const AddTeacher = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const history = useHistory()

  return (
    <>
      <div>
        <Link to="/teachers" className="text-blue-700 hover:text-blue-600">
          Back to Teachers
        </Link>
      </div>

      <div className="text-2xl font-bold pb-4">Add Teacher</div>
      <Row label="First Name">
        <input
          value={firstName}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Row>
      <Row label="Last Name">
        <input
          value={lastName}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setLastName(e.target.value)}
        />
      </Row>
      <Row label="Email">
        <input
          value={email}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Row>
      <Row label="Teacher is a SAMTA Administrator">
        <Toggle value={isAdmin} onClick={() => setIsAdmin(!isAdmin)} />
      </Row>
      <div className="text-right">
        <Button color="white" onClick={() => history.push('/teachers')}>
          Cancel
        </Button>
        <Button
          color="pink"
          onClick={async () => {
            await addTeacher({ firstName, lastName, email, isAdmin })
            history.push('/teachers')
          }}
        >
          Finish Adding Teacher
        </Button>
      </div>
    </>
  )
}

const Row = ({ label, children }: { label: string; children: any }) => (
  <div className="flex flex-col sm:flex-row my-2">
    <div className="sm:w-1/3 font-bold">{label}</div>
    <div className="sm:w-2/3">{children}</div>
  </div>
)

export default AddTeacher

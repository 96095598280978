import React from 'react'
import { updateEvent, useEvent, NewEvent } from '../../api'
import EventEditor from './Editor'
import { useParams } from 'react-router'

const EditEvent = () => {
  const { eventId } = useParams()
  const { data: event, loading } = useEvent(eventId || '')
  if (loading) return <div>Loading...</div>
  if (!event) return <div>Event not found.</div>

  return (
    <EventEditor
      title="Edit Event"
      event={event}
      onSubmit={(e: NewEvent) => updateEvent(event.eventId, e)}
      submitText="Update Event"
    />
  )
}
export default EditEvent

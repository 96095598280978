import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button } from '../../Common'
import { NewStudent, Student, deleteStudent } from '../../api'
import { Skill, skills, renderSkill } from '../../utils'

const StudentEditor = ({
  title,
  student,
  onSubmit,
  submitText,
}: {
  title: string
  student?: Student
  onSubmit: (event: NewStudent) => void
  submitText: string
}) => {
  const [firstName, setFirstName] = useState(student?.firstName || '')
  const [lastName, setLastName] = useState(student?.lastName || '')
  const [instrument, setInstrument] = useState(student?.instrument || '')
  const [age, setAge] = useState(student?.age || '')
  const [grade, setGrade] = useState(student?.grade || '')
  const [skill, setSkill] = useState(student?.skill || skills[0])
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  const history = useHistory()

  return (
    <>
      <div>
        <Link to="/students" className="text-blue-700 hover:text-blue-600">
          Back to Students
        </Link>
      </div>

      <div className="text-2xl font-bold pb-4">{title}</div>
      <Row label="First Name">
        <input
          value={firstName}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Row>
      <Row label="Last Name">
        <input
          value={lastName}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setLastName(e.target.value)}
        />
      </Row>
      <Row label="Instrument">
        <input
          value={instrument}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setInstrument(e.target.value)}
        />
      </Row>
      <Row label="Age">
        <input
          value={age}
          type="number"
          step="1"
          min="0"
          max="99"
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setAge(e.target.value)}
        />
      </Row>
      <Row label="Grade in School">
        <input
          value={grade}
          type="number"
          step="1"
          min="0"
          max="12"
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setGrade(e.target.value)}
        />
      </Row>
      <Row label="Skill">
        <select
          className="px-2 py-1 rounded-sm border bg-blue-100"
          value={skill}
          onChange={(e) => setSkill(e.target.value as Skill)}
        >
          {skills.map((s) => (
            <option value={s} key={s}>
              {renderSkill(s)}
            </option>
          ))}
        </select>
      </Row>
      <div className="text-right">
        <Button color="white" onClick={() => history.push('/students')}>
          Cancel
        </Button>
        {student &&
          (confirmingDelete ? (
            <Button
              color="red"
              onClick={async () => {
                if (!student?.studentId) return
                await deleteStudent(student.studentId)
                history.push('/students')
              }}
            >
              Confirm Delete
            </Button>
          ) : (
            <Button color="white" onClick={() => setConfirmingDelete(true)}>
              Delete Student
            </Button>
          ))}
        <Button
          color="pink"
          onClick={async () => {
            await onSubmit({
              firstName,
              lastName,
              instrument,
              age: parseInt(age + ''),
              grade: grade ? parseInt(grade + '') : undefined,
              skill,
            })
            history.push('/students')
          }}
        >
          {submitText}
        </Button>
      </div>
    </>
  )
}

const Row = ({ label, children }: { label: string; children: any }) => (
  <div className="flex flex-col sm:flex-row my-2">
    <div className="sm:w-1/3 font-bold">{label}</div>
    <div className="sm:w-2/3">{children}</div>
  </div>
)

export default StudentEditor

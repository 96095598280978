import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useEvents, Event, useMyRegistrationCount } from '../../api'
import { renderDate, renderDateTime } from '../../utils'
import { Loading } from '../../Common'
import { AdminContext } from '../../Context'

const Events = () => {
  const { data: events, loading } = useEvents()
  const [pastEventLimit, setPastEventLimit] = useState(5)
  const isAdmin = useContext(AdminContext)

  const upcomingEvents = (events || []).filter(
    (e) => !e.date || e.date.getTime() >= Date.now()
  )
  const pastEvents = (events || []).filter(
    (e) => e.date && e.date.getTime() < Date.now()
  )

  if (loading) return <Loading />
  return (
    <>
      <div className="text-2xl font-bold mx-2 pb-4 flex">Upcoming Events</div>
      <div className="flex sm:flex-column flex-wrap ">
        {upcomingEvents.map((e, i) => (
          <EventBlock event={e} key={i} />
        ))}
        {isAdmin && (
          <Link to="/events/add" className="w-full sm:w-auto">
            <div className="sm:w-64 w-full sm:h-48 bg-gray-100 border-gray-200 border-2 rounded-lg p-4 my-2 sm:m-2 text-pink-600 text-lg hover:border-pink-600 cursor-pointer flex items-center justify-center font-bold">
              + Add Event
            </div>
          </Link>
        )}
      </div>
      <div className="text-2xl font-bold mx-2 mt-8 pb-4 flex">Past Events</div>
      <div className="flex flex-row flex-wrap ">
        {pastEvents.slice(0, pastEventLimit + 1).map((e, i) => (
          <EventBlock event={e} key={i} />
        ))}
        {pastEvents.length > pastEventLimit && (
          <div
            className="w-full sm:w-auto"
            onClick={() => setPastEventLimit(pastEventLimit + 6)}
          >
            <div className="sm:w-64 w-full sm:h-48 bg-gray-100 border-gray-200 border-2 rounded-lg p-4 my-2 sm:m-2 text-gray-600 text-lg hover:border-gray-500 cursor-pointer flex items-center justify-center font-bold">
              See Older Events
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Events

const EventBlock = ({ event }: { event: Event }) => {
  const { data: registrationCount } = useMyRegistrationCount(event.eventId)

  return (
    <Link to={`/events/${event.eventId}`} className="w-full sm:w-auto">
      <div className="sm:w-64 w-full sm:h-48 bg-gray-100 border-gray-200 border-2 rounded-lg p-4 my-2 sm:m-2 hover:border-blue-600 cursor-pointer">
        <div className="text-lg font-semibold pb-2">{event.name}</div>
        <div>{renderDateTime(event.date)}</div>
        <div className="italic">
          Register by {renderDate(event.deadlineDate)}
        </div>
        {!!registrationCount && (
          <div className="font-bold text-green-600 mt-4">
            {registrationCount}{' '}
            {registrationCount === 1 ? 'student' : 'students'} registered
          </div>
        )}
      </div>
    </Link>
  )
}

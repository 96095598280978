export enum Skill {
  Beginner = 'beginner',
  LateBeginner = 'late_beginner',
  EarlyIntermediate = 'early_intermediate',
  MidIntermediate = 'mid_intermediate',
  LateIntermediate = 'late_intermediate',
  Advanced = 'advanced',
}
export const skills = [
  Skill.Beginner,
  Skill.LateBeginner,
  Skill.EarlyIntermediate,
  Skill.MidIntermediate,
  Skill.LateIntermediate,
  Skill.Advanced,
]

export const renderSkill = (s: Skill) => {
  switch (s) {
    case Skill.Beginner:
      return 'Beginner'
    case Skill.LateBeginner:
      return 'Late Beginner'
    case Skill.EarlyIntermediate:
      return 'Early Intermediate'
    case Skill.MidIntermediate:
      return 'Mid Intermediate'
    case Skill.LateIntermediate:
      return 'Late Intermediate'
    case Skill.Advanced:
      return 'Advanced'
    default:
      return ''
  }
}

export const renderDate = (date?: Date): string =>
  date
    ? date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'America/Chicago',
      })
    : ''

export const renderDateTime = (date?: Date): string =>
  date
    ? date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: 'America/Chicago',
      })
    : ''

export const renderDollars = (dollars: number) =>
  dollars % 1 === 0 ? `$${dollars}` : `$${dollars.toFixed(2)}`

import React from 'react'
import { updateStudent, useStudent, NewStudent } from '../../api'
import StudentEditor from './Editor'
import { useParams } from 'react-router'

const EditStudent = () => {
  const { studentId } = useParams()
  const { data: student, loading } = useStudent(studentId || '')
  if (loading) return <div>Loading...</div>
  if (!student) return <div>Student not found.</div>

  return (
    <StudentEditor
      title="Edit Student"
      student={student}
      onSubmit={(s: NewStudent) => updateStudent(student.studentId, s)}
      submitText="Update Student"
    />
  )
}
export default EditStudent

import React, { useState } from 'react'
import { Button } from '../../Common'
import { Event, NewEvent, deleteEvent } from '../../api'
import update from 'immutability-helper'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

const EventEditor = ({
  event,
  title,
  onSubmit,
  submitText,
}: {
  event?: Event
  title: string
  onSubmit: (event: NewEvent) => void
  submitText: string
}) => {
  const [eventName, setEventName] = useState(event?.name || '')
  const [eventDate, setEventDate] = useState(event?.date || null)
  const [deadlineDate, setDeadlineDate] = useState(event?.date || null)
  const [maxEntries, setMaxEntries] = useState(event?.maxEntries + '' || '0')
  const [host, setHost] = useState(event?.host || '')
  const [fee, setFee] = useState(event?.fee + '' || '0')
  const [description, setDescription] = useState(event?.description || '')
  const [location, setLocation] = useState(event?.location || '')
  const [columns, setColumns] = useState(event?.columns || [])
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmingDelete, setConfirmingDelete] = useState(false)

  return (
    <>
      <div>
        <Link to="/events" className="text-blue-700 hover:text-blue-600">
          Back to Events
        </Link>
      </div>

      <div className="text-2xl font-bold pb-4">{title}</div>
      <Row label="Event Name">
        <input
          value={eventName}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setEventName(e.target.value)}
          placeholder="Winter Recital"
        />
      </Row>
      <Row label="Event Date">
        <div>
          <DatePicker
            selected={eventDate}
            className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
            onChange={(date) => setEventDate(date as Date | null)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
          />
        </div>
      </Row>
      <Row label="Registration Deadline">
        <div>
          <DatePicker
            selected={deadlineDate}
            className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
            onChange={(date) => setDeadlineDate(date as Date | null)}
            timeInputLabel="Time:"
            dateFormat="MM/dd/yyyy h:mm aa"
            showTimeInput
          />
        </div>
      </Row>
      <Row label="Maximum Number of Students">
        <input
          type="number"
          min={0}
          step={1}
          value={maxEntries}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setMaxEntries(e.target.value)}
        />
      </Row>
      <Row label="Hosted By">
        <input
          value={host}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setHost(e.target.value)}
          placeholder="John Smith"
        />
      </Row>
      <Row label="Fee">
        <input
          value={fee}
          type="number"
          min={0}
          step={0.01}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setFee(e.target.value)}
        />
      </Row>
      <Row label="Event Location">
        <input
          value={location}
          className="px-2 py-1 rounded-sm border bg-blue-100 w-64"
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Event Center, 1200 Main St, San Antonio, TX 78249 "
        />
      </Row>

      <Row label="Event Description">
        <textarea
          value={description}
          placeholder="A wintertime recital for all students."
          className="px-2 py-1 rounded-sm border bg-blue-100 h-32 w-full"
          onChange={(e) => setDescription(e.target.value)}
        />
      </Row>
      <Row label="Registration Form Columns">
        {columns.map((c, i) => (
          <div
            className="flex flex-col sm:flex-row sm:items-center border-b border-b-gray-200 pb-4 mb-2"
            key={i}
          >
            <div className="mt-4 sm:mt-0 pt-2">Column Name:</div>
            <input
              type="text"
              value={c.name}
              disabled={c.fixed}
              className={`sm:ml-2 px-2 py-1 rounded-sm border ${
                c.fixed ? 'bg-gray-100' : 'bg-blue-100'
              }`}
              onChange={(e) =>
                setColumns(
                  update(columns, { [i]: { name: { $set: e.target.value } } })
                )
              }
            />
            <div className="sm:ml-2 pt-2">Information Type:</div>
            <select
              className={`sm:ml-2 h-8 rounded-sm border ${
                c.fixed ? 'bg-gray-100' : 'bg-blue-100'
              }`}
              disabled={c.fixed}
              onChange={(e) =>
                setColumns(
                  // @ts-ignore
                  update(columns, {
                    [i]: { type: { $set: e.target.value } },
                  })
                )
              }
            >
              <option value="string">Text</option>
              <option value="textarea">Multiline Text</option>
              <option value="number">Number</option>
              <option value="checkbox">Checkbox</option>
            </select>
            <div className="sm:ml-2 pt-2">Required:</div>
            <input
              type="checkbox"
              className="sm:ml-2 px-2 py-1 rounded-sm border bg-blue-100"
              disabled={c.fixed}
              checked={c.required}
              onChange={(e) =>
                setColumns(
                  update(columns, {
                    [i]: { required: { $set: !c.required } },
                  })
                )
              }
            />
            {!c.fixed ? (
              <div
                onClick={() =>
                  setColumns(update(columns, { $splice: [[i, 1]] }))
                }
                className="sm:ml-2 cursor-pointer rounded-sm bg-red-200 text-center shadow text-red-600 font-bold px-2 py-1 w-8 mt-4 sm:mt-0"
              >
                X
              </div>
            ) : (
              <div className="w-8" />
            )}
          </div>
        ))}
        <div
          className="text-center cursor-pointer font-bold text-pink-600 hover:text-pink-700"
          onClick={(e) =>
            setColumns(
              columns.concat({
                name: '',
                type: 'string',
                fixed: false,
                required: false,
              })
            )
          }
        >
          + Add Another Column
        </div>
      </Row>
      <div className="pt-8 text-right">
        <Button color="white" onClick={() => history.push('/events')}>
          Cancel
        </Button>
        {event &&
          (confirmingDelete ? (
            <Button
              color="red"
              onClick={async () => {
                if (!event?.eventId) return
                await deleteEvent(event.eventId)
                history.push('/events')
              }}
            >
              Confirm Delete
            </Button>
          ) : (
            <Button color="white" onClick={() => setConfirmingDelete(true)}>
              Delete Event
            </Button>
          ))}
        <Button
          color="pink"
          onClick={async () => {
            const dups = columns.reduce((set, c) => {
              if (set === true) return true
              if (set.has(c.name)) return true
              set.add(c.name)
              return set
            }, new Set() as Set<string> | true)

            if (!eventName) {
              setErrorMessage('Please enter a name for the event.')
            } else if (dups === true) {
              setErrorMessage(
                'Two columns have the same name. Each column must have a unique name.'
              )
            } else {
              await onSubmit({
                name: eventName,
                date: eventDate || undefined,
                deadlineDate: deadlineDate || undefined,
                maxEntries: parseFloat(maxEntries),
                host,
                fee: parseFloat(fee) || 0,
                description,
                location,
                columns,
              })
              history.push('/events/')
            }
          }}
          className="inline-block"
        >
          {submitText}
        </Button>
        <div className="font-bold text-red-600 inline-block pl-4">
          {errorMessage}
        </div>
      </div>
    </>
  )
}

const Row = ({ label, children }: { label: string; children: any }) => (
  <div className="flex flex-col sm:flex-row my-2">
    <div className="sm:w-1/3 font-bold">{label}</div>
    <div className="sm:w-2/3">{children}</div>
  </div>
)

export default EventEditor

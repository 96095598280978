import { useState, useEffect, useMemo } from 'react'
import { Skill } from './utils'
import { Auth } from '@aws-amplify/auth'

export interface NewEvent {
  name: string
  date?: Date
  deadlineDate?: Date
  fee?: number
  maxEntries?: number
  host?: string
  description?: string
  location?: string
  columns?: Column[]
}
export interface Event extends NewEvent {
  eventId: string
}
export interface Column {
  name: string
  type: 'string' | 'textarea' | 'checkbox' | 'number'
  placeholder?: string
  fixed: boolean
  required: boolean
}

export interface NewStudent {
  firstName: string
  lastName: string
  instrument: string
  age?: number
  grade?: number
  skill: Skill
}
export interface Student extends NewStudent {
  studentId: string
}

export interface NewTeacher {
  firstName: string
  lastName: string
  email: string
  isAdmin: boolean
}
export interface Teacher extends NewTeacher {
  teacherId: string
}

interface API<T> {
  loading: boolean
  error?: any
  data?: T
}

export interface Registration {
  eventId: string
  studentId: string
  paypalOrderId: string | null
  columns: {
    [key: string]: any
  }
  [key: string]: any
}

const BASE_URL = process.env.REACT_APP_API_ENDPOINT

const customFetch = async (url: string, options: { [key: string]: any }) => {
  const session = await Auth.currentSession()
  return fetch(BASE_URL + url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authorization: session.getAccessToken().getJwtToken(),
    },
  })
}

const useFetch = (url: string, options?: any) => {
  const [data, setData] = useState(undefined)
  const [error, setError] = useState(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(undefined)
      setData(undefined)
      try {
        const res = await customFetch(url, options)
        const json = await res.json()
        setData(json)
      } catch (error) {
        setError(error)
        console.log(error)
      }
      setLoading(false)
    }
    fetchData()
  }, [url, options])
  return { data, error, loading }
}

const mapEvent = (e: Event): Event => ({
  ...e,
  date: e.date ? new Date(e.date) : undefined,
  deadlineDate: e.deadlineDate ? new Date(e.deadlineDate) : undefined,
})
export const useEvents = (): API<Event[]> => {
  const { data, ...res } = useFetch('/events')
  const mappedData = useMemo(
    () => (data ? (data! as Event[]).map(mapEvent) : data),
    [data]
  )
  return {
    data: mappedData,
    ...res,
  }
}
export const useEvent = (eventId: string): API<Event> => {
  const { data, error, loading } = useFetch(`/events/${eventId}`)
  const mappedData = useMemo(() => (data ? mapEvent(data!) : undefined), [data])
  return { data: mappedData, error, loading }
}
export const useRegistrations = (eventId: string): API<Registration[]> =>
  useFetch(`/events/${eventId}/registrations`)
export const useMyRegistrationCount = (eventId: string): API<number> =>
  useFetch(`/events/${eventId}/registrations/mycount`)
export const useRegistrationCount = (eventId: string): API<number> =>
  useFetch(`/events/${eventId}/registrations/count`)
export const addEvent = (event: NewEvent) =>
  customFetch('/events', {
    method: 'POST',
    body: JSON.stringify(event),
  })
export const updateEvent = (eventId: string, event: NewEvent) =>
  customFetch('/events', {
    method: 'POST',
    body: JSON.stringify({ ...event, eventId }),
  })
export const deleteEvent = (eventId: string) =>
  customFetch('/events/' + eventId, { method: 'DELETE' })
export const registerEvent = (
  eventId: string,
  students: any[],
  paypalOrderId: string | null
) =>
  customFetch(`/events/${eventId}/register`, {
    method: 'POST',
    body: JSON.stringify({ students, paypalOrderId }),
  })

export const useStudents = (): API<Student[]> => useFetch('/students')
export const useStudent = (studentId: string): API<Student> =>
  useFetch(`/students/${studentId}`)
export const addStudent = (student: NewStudent) =>
  customFetch('/students', {
    method: 'POST',
    body: JSON.stringify(student),
  })
export const updateStudent = (studentId: string, student: NewStudent) =>
  customFetch('/students', {
    method: 'POST',
    body: JSON.stringify({ ...student, studentId }),
  })
export const deleteStudent = (studentId: string) =>
  customFetch('/students/' + studentId, { method: 'DELETE' })

export const useTeachers = (): API<Teacher[]> => useFetch('/teachers')
export const addTeacher = (teacher: NewTeacher) =>
  customFetch('/teachers', {
    method: 'POST',
    body: JSON.stringify(teacher),
  })
export const updateTeacher = ({
  email,
  isAdmin,
}: {
  email: string
  isAdmin: boolean
}) =>
  customFetch(`/teachers/${email}`, {
    method: 'PUT',
    body: JSON.stringify({ isAdmin }),
  })
export const setTeacherPassword = ({
  email,
  password,
}: {
  email: string
  password: string
}) =>
  customFetch(`/teachers/${email}/setPassword`, {
    method: 'POST',
    body: JSON.stringify({ password }),
  })
export const deleteTeacher = (email: string) =>
  customFetch('/teachers/' + email, { method: 'DELETE' })

import React, { useState, useEffect } from 'react'
import Students from './Routes/Students'
import AddStudent from './Routes/Students/Add'
import EditStudent from './Routes/Students/Edit'
import Events from './Routes/Events'
import ViewEvent from './Routes/Events/View'
import RegisterEvent from './Routes/Events/Register'
import AddEvent from './Routes/Events/Add'
import EditEvent from './Routes/Events/Edit'
import Teachers from './Routes/Teachers'
import AddTeacher from './Routes/Teachers/Add'
import EditTeacher from './Routes/Teachers/Edit'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  Redirect,
  Link,
} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMusic } from '@fortawesome/free-solid-svg-icons'
import { Auth } from '@aws-amplify/auth'
import {
  AmplifyContainer,
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifySignUp,
} from '@aws-amplify/ui-react'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { AdminContext } from './Context'

import './App.scss'

Auth.configure({
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
})

function App() {
  const [openMenu, setOpenMenu] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [authState, setAuthState] = React.useState<AuthState>()
  const [user, setUser] = React.useState<object | undefined>()

  useEffect(() => {
    onAuthUIStateChange(async (nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
      if (!authData) return

      const session = await Auth.currentSession()
      const payload = session.getAccessToken().decodePayload()
      const isAdmin = (payload['cognito:groups'] || []).indexOf('admin') !== -1
      setIsAdmin(isAdmin)
    })
  }, [])

  return authState === AuthState.SignedIn && user ? (
    <Router>
      <AdminContext.Provider value={isAdmin}>
        <div className="app bg-gray-300 font-sans leading-normal tracking-normal overflow-y-auto pb-4">
          <nav className="flex items-center justify-between flex-wrap bg-blue-700 px-6 py-4 fixed w-full z-10 top-0 shadow-md">
            <div className="flex flex-shrink-0 text-white mr-6">
              <Link
                className="text-white no-underline hover:text-white hover:no-underline"
                to="/"
              >
                <span className="text-2xl pl-2">
                  <FontAwesomeIcon icon={faMusic} className="opacity-25 mr-2" />{' '}
                  SAMTA
                </span>
              </Link>
              <span
                onClick={() => Auth.signOut()}
                className="cursor-pointer inline-block py-1 my-1 mx-4 text-md text-white no-underline border-b-2 border-blue-700 opacity-50"
              >
                Logout
              </span>
            </div>

            <div className="block sm:hidden">
              <button
                id="nav-toggle"
                className="flex items-center px-3 py-2 border rounded text-white border-white"
                onClick={() => setOpenMenu(!openMenu)}
                style={{ outline: 'none' }}
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>

            <div
              className={`w-full flex-grow sm:items-center sm:w-auto sm:block pt-6 sm:pt-0 ${
                openMenu ? '' : 'hidden'
              }`}
            >
              <ul className="list-reset sm:flex justify-end flex-1 items-center">
                <NavItem path="/events">Events</NavItem>
                <NavItem path="/students">My Students</NavItem>
                <AdminContext.Consumer>
                  {(isAdmin) =>
                    isAdmin && <NavItem path="/teachers">Teachers</NavItem>
                  }
                </AdminContext.Consumer>
              </ul>
            </div>
          </nav>
          <div className="container shadow-lg mx-auto bg-white mt-24 app-content px-10 py-10 overflow-auto flex-1 rounded-sm">
            <Switch>
              <Route path="/students/:studentId/edit" component={EditStudent} />
              <Route path="/students/add" component={AddStudent} />
              <Route path="/students" component={Students} />
              <Route path="/events/add" component={AddEvent} />
              <Route path="/events/:eventId/edit" component={EditEvent} />
              <Route
                path="/events/:eventId/register"
                component={RegisterEvent}
              />
              <Route path="/events/:eventId" component={ViewEvent} />
              <Route path="/events" component={Events} />
              <Route path="/teachers/add" component={AddTeacher} />
              <Route path="/teachers/:teacherId" component={EditTeacher} />
              <Route path="/teachers" component={Teachers} />
              <Redirect exact from="/" to="/events" />
            </Switch>
          </div>
        </div>
      </AdminContext.Provider>
    </Router>
  ) : (
    <AmplifyContainer>
      <AmplifyAuthenticator usernameAlias="email">
        <AmplifySignIn slot="sign-in" usernameAlias="email" />
        <AmplifySignUp
          slot="sign-up"
          usernameAlias="email"
          formFields={[
            {
              label: 'First Name',
              required: true,
              type: 'given_name',
            },
            {
              label: 'Last Name',
              required: true,
              type: 'family_name',
            },
            {
              label: 'Email',
              required: true,
              type: 'email',
            },
            {
              label: 'Password',
              required: true,
              type: 'password',
            },
          ]}
        />
      </AmplifyAuthenticator>
    </AmplifyContainer>
  )
}

interface NavItem {
  path: string
  children: any
}
const NavItem: React.SFC<NavItem> = ({ path, children }) => {
  const match = useRouteMatch(path)
  return (
    <li className="mr-3">
      <NavLink
        to={path}
        className={
          match
            ? 'inline-block py-1 my-1 mx-4 text-lg text-white no-underline border-b-2 border-b-white'
            : 'inline-block py-1 my-1 mx-4 text-lg text-white no-underline border-b-2 border-blue-700'
        }
      >
        {children}
      </NavLink>
    </li>
  )
}

export default App

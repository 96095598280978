import React from 'react'
import { addStudent } from '../../api'
import StudentEditor from './Editor'

const AddStudent = () => (
  <StudentEditor
    title="Add Student"
    onSubmit={addStudent}
    submitText="Finish Adding Student"
  />
)

export default AddStudent

import React, { useContext } from 'react'
import { useRegistrations, Event } from '../../api'
import { AdminContext } from '../../Context'
import { renderSkill } from '../../utils'

const ViewRegistrations = ({ event }: { event: Event }) => {
  const { data: registrations } = useRegistrations(event.eventId)
  const columns = event.columns || []
  const isAdmin = useContext(AdminContext)

  if (!registrations?.length) return null

  return (
    <>
      <hr className="my-4" />
      <table className="table-auto mt-8 w-full">
        <thead>
          <tr>
            {isAdmin && (
              <td className="font-bold text-center px-4 py-2">Teacher</td>
            )}
            <td className="font-bold text-center px-4 py-2">First Name</td>
            <td className="font-bold text-center px-4 py-2">Last Name</td>
            {event.fee ? (
              <td className="font-bold text-center px-4 py-2">Paid Fee</td>
            ) : null}
            <td className="font-bold text-center px-4 py-2">Instrument</td>
            <td className="font-bold text-center px-4 py-2">Age</td>
            <td className="font-bold text-center px-4 py-2">Grade</td>
            <td className="font-bold text-center px-4 py-2">Skill</td>
            {columns.map((c, i) => (
              <td key={i} className="font-bold text-center px-4 py-2">
                {c.name}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {(registrations || []).map((r, i) => (
            <tr key={i}>
              {isAdmin && <Cell>{r.teacher}</Cell>}
              <Cell>{r.firstName}</Cell>
              <Cell>{r.lastName}</Cell>
              {event.fee ? <Cell>{r.paypalOrderId ? 'Yes' : 'No'}</Cell> : null}
              <Cell>{r.instrument}</Cell>
              <Cell>{r.age}</Cell>
              <Cell>{r.grade}</Cell>
              <Cell>{renderSkill(r.skill)}</Cell>
              {columns.map((c, j) => {
                let value = (r.columns || {})[c.name]

                if (typeof value === 'boolean') value = value ? 'Yes' : 'No'
                else if (value === undefined) value = ''
                else value = value + ''

                return <Cell key={j}>{value}</Cell>
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="text-blue-700 cursor-pointer mt-2 text-right"
        onClick={() => {
          const rows = (registrations || []).map((r, i) => [
            r.teacher,
            r.firstName,
            r.lastName,
            r.instrument,
            r.age,
            r.grade,
            renderSkill(r.skill),
            ...columns.map((c) => {
              let value = (r.columns || {})[c.name]

              if (typeof value === 'boolean') return value ? 'Yes' : 'No'
              else if (value === undefined) return ''
              else return value + ''
            }),
          ])
          const header = [
            'Teacher',
            'First Name',
            'Last Name',
            'Instrument',
            'Age',
            'Grade',
            'Skill',
            ...columns.map((c) => c.name),
          ]
          exportToCsv('registrations.csv', [header, ...rows])
        }}
      >
        Download registrations as a spreadsheet.
      </div>
    </>
  )
}

const Cell = ({ children }: { children: any }) => (
  <td className="border px-4 py-2 student-field">
    <div className="px-2 py-1 border border-1 border-transparent">
      {children}
    </div>
  </td>
)

function exportToCsv(filename: string, rows: string[][]) {
  const processRow = function (row: (string | number | undefined)[]) {
    let finalVal = ''
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] || row[j] === 0 ? row[j] + '' : ''
      let result = innerValue.replace(/"/g, '""')
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"'
      if (j > 0) finalVal += ','
      finalVal += result
    }
    return finalVal + '\n'
  }

  let csvFile = ''
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i])
  }

  let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    let link = document.createElement('a')
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      let url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export default ViewRegistrations

import React from 'react'
import { useStudents } from '../../api'
import { renderSkill } from '../../utils'
import { useHistory } from 'react-router'

export default () => {
  const { data, loading } = useStudents()
  const students = data || []
  const history = useHistory()

  return (
    <div>
      <div className="text-2xl font-bold">Students</div>
      <table className="table-auto mt-8 mx-auto">
        {students.length > 0 && (
          <thead>
            <tr>
              <td className="font-bold text-center px-4 py-2">Name</td>
              <td className="font-bold text-center px-4 py-2">Instrument</td>
              <td className="font-bold text-center px-4 py-2">Age</td>
              <td className="font-bold text-center px-4 py-2">Grade</td>
              <td className="font-bold text-center px-4 py-2">Skill</td>
            </tr>
          </thead>
        )}
        <tbody>
          {students.length === 0 && !loading && (
            <tr>
              <td colSpan={4}>You haven't added any students yet.</td>
            </tr>
          )}
          {students.map((s, i) => (
            <tr
              key={i}
              className="cursor-pointer hover:bg-gray-100"
              onClick={() => history.push(`/students/${s.studentId}/edit`)}
            >
              <td className="border px-4 py-2 student-field">
                <div className="px-2 py-1 border border-1 border-transparent">
                  {s.firstName} {s.lastName}
                </div>
              </td>
              <td className="border px-4 py-2 student-field">
                <div className="px-2 py-1 border border-1 border-transparent">
                  {s.instrument}
                </div>
              </td>
              <td className="border px-4 py-2 student-field">
                <div className="px-2 py-1 border border-1 border-transparent">
                  {s.age}
                </div>
              </td>
              <td className="border px-4 py-2 student-field">
                <div className="px-2 py-1 border border-1 border-transparent">
                  {s.grade}
                </div>
              </td>
              <td className="border px-4 py-2 student-field">
                <div className="px-2 py-1 border border-1 border-transparent">
                  {renderSkill(s.skill)}
                </div>
              </td>
            </tr>
          ))}
          <tr>
            <td
              className="border px-4 py-2 cursor-pointer text-center font-bold hover:bg-gray-100 text-pink-600 hover:text-pink-700"
              colSpan={5}
              onClick={() => history.push('/students/add')}
            >
              + Add Student
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

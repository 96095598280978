import React, { useContext } from 'react'
import { Button } from '../../Common'
import { useEvent, useRegistrationCount } from '../../api'
import { Link, useParams } from 'react-router-dom'
import { renderDateTime, renderDollars } from '../../utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Loading } from '../../Common'
import ViewRegistrations from './ViewRegistrations'
import { AdminContext } from '../../Context'

export default () => {
  const { eventId } = useParams<{ eventId: string }>()
  const { data: event, loading } = useEvent(eventId || '')
  const isAdmin = useContext(AdminContext)
  const {
    data: registrationCount,
    loading: loadingRegistartionCount,
  } = useRegistrationCount(eventId || '')

  if (loading) return <Loading />
  if (!event) return <span>Event not found.</span>

  let registrationCountText: string | null = null
  if (!loadingRegistartionCount && event.maxEntries) {
    const spotsRemaining = (event.maxEntries || 0) - (registrationCount || 0)
    registrationCountText = `${spotsRemaining} ${
      spotsRemaining === 1 ? 'spot' : 'spots'
    } remaining`
  }

  return (
    <>
      <div className="flex">
        <div>
          <Link to="/events" className="text-blue-700 hover:text-blue-600">
            Back to Events
          </Link>
        </div>
        <div className="ml-2 flex-grow text-right">
          {isAdmin && (
            <Link
              to={`/events/${eventId}/edit`}
              className="text-blue-700 hover:text-blue-600"
            >
              <FontAwesomeIcon icon={faEdit} /> Edit this event
            </Link>
          )}
        </div>
      </div>
      <div className="text-2xl font-bold">{event.name}</div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex-auto">
          <div>{renderDateTime(event.date)}</div>
          {event.location && <div>{event.location}</div>}
          {event.host && <div>Hosted by: {event.host}</div>}
          <div>Fee: {renderDollars(event.fee || 0)}</div>
          {registrationCountText && <div>{registrationCountText}</div>}
        </div>
        <div className="pt-4 sm:pt-0 sm:text-right">
          {(!event.deadlineDate ||
            event.deadlineDate.getTime() > Date.now()) && (
            <Button
              to={`/events/${eventId}/register`}
              className="w-32"
              color="pink"
            >
              Register
            </Button>
          )}
          {event.deadlineDate && (
            <div className="italic text-gray-600">
              Register by {renderDateTime(event.deadlineDate)}
            </div>
          )}
        </div>
      </div>
      {(event.description || '')
        .split('\n')
        .map((paragraph) => paragraph.trim())
        .filter((paragraph) => paragraph.length > 0)
        .map((paragraph, i) => (
          <p key={i} className="pt-4">
            {paragraph}
          </p>
        ))}
      <ViewRegistrations event={event} />
    </>
  )
}
